<script>
import { productService } from "../../../helpers/backend/product.service";
import { vehicleTypeService } from "../../../helpers/backend/vehicle_type.service";
export default {
  components: {},
  data() {
    return {
      form: {
        id: null,
        product_name: "",
        description: "",
        unit: "",
        price: 0,
        qty: 0,
        vehicle_type_id: 0,
      },
      vehicle_brand : null,
      busy: false,
      vehicle_type_options: [],
      vehicle_brand_options: ["Universal"],
      show: true,
      showDismissibleAlert: false,
      error: "",
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    isUpdateState() {
      return this.form.id != null;
    },
    computed_vehicle_type_options() {
      return this.vehicle_type_options[this.vehicle_brand];
    },
    isVehicleTypeRequired() {
      return this.vehicle_brand != null && this.vehicle_brand != "Universal";
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      //this.form.media = this.$refs.file.files[0]
      this.busy = true;
      productService.createOrUpdate(this.form).then(
        (data) => {
          if (data != null) {
            this.reset();
            this.$emit("refresh");
            this.showDismissibleAlert = true;
            this.error = "";
          }
          this.busy = false;
        },
        (error) => {
          this.showDismissibleAlert = true;
          this.error = "Failed to Insert/Update Customer. Error : " + error;
          this.busy = false;
        }
      );
    },
    reset() {
      // Reset our form values
      this.form.id = null;
      this.form.product_name = "";
      this.form.description = "";
      this.form.unit = "";
      this.form.price = 0;
      this.form.qty = 0;
      this.form.vehicle_type_id = 0;
      this.vehicle_brand = null;
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.showDismissibleAlert = false;
      this.error = "";
      this.$nextTick(() => {
        this.show = true;
      });
    },
    onReset(event) {
      event.preventDefault();
      this.reset();
    },
    initUpdate(id) {
      this.reset();
      this.$refs.name.$el.focus();
      this.busy = true;
      productService.getById(id).then(
        (data) => {
          if (data.product != null) {
            this.form.id = data.product.ID;
            this.form.product_name = data.product.product_name;
            this.form.description = data.product.description;
            this.form.unit = data.product.unit;
            this.form.price = data.product.price;
            this.form.qty = data.product.qty;
            this.form.vehicle_type_id = data.product.vehicle_type_id.Int32;
          } else {
            this.showDismissibleAlert = true;
            this.error =
              "Failed to get product to update. Error : product not found";
          }
          this.busy = false;
        },
        (err) => {
          this.showDismissibleAlert = true;
          this.error = "Failed to get product to update. Error : " + err;
          this.busy = false;
        }
      );
    },
    fetchData() {
      vehicleTypeService.getAll().then(
        (data) => {
          this.vehicle_brand_options = ["Universal"];
          for (let index = 0; index < data.vehicle_types.length; index++) {
            const element = data.vehicle_types[index];
            if (!this.vehicle_brand_options.includes(element.vehicle_brand)) {
              this.vehicle_brand_options.push(element.vehicle_brand);
              this.vehicle_type_options[element.vehicle_brand] = [
                { text: "Select One", value: null },
              ];
            }
            this.vehicle_type_options[element.vehicle_brand].push({
              text: element.vehicle_series,
              value: element.ID,
            });
          }
          //alert(typeof(this.machine_types))
        },
        (err) => {
          this.showDismissibleAlert = true;
          this.error = "Failed to get vehicle type. Error : " + err;
        }
      );
    },
  },
};
</script>


<template>
  <div>
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
            <b-overlay :show="busy" rounded="lg" opacity="0.6">
              <b-form-group
                id="input-group-1"
                label="Product Name:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.product_name"
                  type="text"
                  placeholder="Enter Product Name"
                  required
                  ref="name"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="Description:"
                label-for="input2"
              >
                <b-form-input
                  id="input-2"
                  v-model="form.description"
                  type="text"
                  placeholder="Enter Product Description"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-3"
                label="Price:"
                label-for="input3"
              >
                <b-form-input
                  id="input-3"
                  v-model="form.price"
                  type="number"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-4"
                label="Quantity:"
                label-for="input4"
              >
                <b-form-input
                  id="input-4"
                  v-model="form.qty"
                  type="number"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-5" label="Unit:" label-for="input5">
                <b-form-input
                  id="input-5"
                  v-model="form.unit"
                  type="text"
                  placeholder="Enter Product Unit"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-6"
                label="Vehicle Type:"
                label-for="input-6"
              >
                <b-form-select
                  id="input-6"
                  v-model="vehicle_brand"
                  :options="vehicle_brand_options"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                  :hidden="!isVehicleTypeRequired"
                id="input-group-7"
                label="Vehicle Type:"
                label-for="input-7"
              >
                <b-form-select
                  id="input-7"
                  v-model="form.vehicle_type_id"
                  :options="computed_vehicle_type_options"
                  :required="isVehicleTypeRequired"
                ></b-form-select>
              </b-form-group>

              <b-button type="submit" variant="primary">{{
                isUpdateState ? "Update" : "Submit"
              }}</b-button>
              <b-button type="reset" variant="danger">Reset</b-button>
            </b-overlay>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>



